import React from 'react';
import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://www.linkedin.com/in/krzysztof-pietruszka/"
          target="_blank"
          rel="noreferrer"
        >
          <BsLinkedin />
        </a>
      </div>
      <div>
        <a href="https://github.com/Sellerek" target="_blank" rel="noreferrer">
          <BsGithub />
        </a>
      </div>
      <div>
        <a href="https://photoclip.pl/" target="_blank" rel="noreferrer">
          <CgWebsite />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
